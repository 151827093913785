import { Menu, Transition } from '@headlessui/react'
import { LanguageIcon } from '@heroicons/react/24/outline'
import { Link, useMatches, useSubmit } from '@remix-run/react'
import { LANGUAGES } from '#app/env.remix/config'
import { cx } from '#app/utils/tailwind'

export function MenuLanguage({ isUser = false }: { isUser?: boolean }) {
	let matches = useMatches()
	let currentLocale = matches[matches.length - 1]?.params.locale ?? ''
	let currentPathname = matches[matches.length - 1]?.pathname ?? ''
	let submit = useSubmit()

	let handleClick = (locale: string, redirectTo: string) => {
		let formData = new FormData()
		formData.append('locale', locale)
		formData.append('redirectTo', redirectTo)
		submit(formData, {
			action: '/api/profile/update-language',
			method: 'post',
		})
	}

	return (
		<Menu as="div" className="relative">
			<Menu.Button
				className="group flex w-12 items-center justify-center sm:w-16"
				aria-label="Languages"
				data-cy="h-languages"
			>
				<span className="relative">
					<LanguageIcon className="h-8 text-slate-200 group-hover:animate-bounce group-hover:text-white" />
					<span className="absolute -bottom-2 left-5 flex h-5 w-7 items-center justify-center rounded-full border border-slate-600 bg-slate-700/90 px-0.5 text-xs uppercase text-slate-300 group-hover:text-white">
						{currentLocale}
					</span>
				</span>
			</Menu.Button>
			<Transition
				enter="transition duration-100 ease-out"
				enterFrom="transform scale-95 opacity-0"
				enterTo="transform scale-100 opacity-100"
				leave="transition duration-75 ease-out"
				leaveFrom="transform scale-100 opacity-100"
				leaveTo="transform scale-95 opacity-0"
			>
				<Menu.Items className="absolute right-0 mt-2 w-fit origin-top-right divide-y divide-slate-100 rounded-md border-slate-300 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
					{LANGUAGES.map((item) => (
						<Menu.Item key={item.lang}>
							{({ active }) => {
								let href = currentPathname.replace(
									`/${currentLocale}/`,
									`/${item.lang}/`,
								)
								return isUser ? (
									<button
										className={cx(
											'flex w-full whitespace-nowrap px-4 py-2 text-sm',
											active ? 'bg-purple-800 text-white' : 'text-slate-700',
										)}
										data-cy={`h-lang-${item.lang}`}
										onClick={() => handleClick(item.lang, href)}
									>
										{item.name}
									</button>
								) : (
									<Link
										className={cx(
											'flex whitespace-nowrap px-4 py-2 text-sm',
											active ? 'bg-purple-800 text-white' : 'text-slate-700',
										)}
										data-cy={`h-lang-${item.lang}`}
										to={href}
									>
										{item.name}
									</Link>
								)
							}}
						</Menu.Item>
					))}
				</Menu.Items>
			</Transition>
		</Menu>
	)
}
